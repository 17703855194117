import { poolList, poolDetail, withDrawList, withdrawDetail } from '@/apis/apiPool';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvestment(ctx, condition) {
      return new Promise((resolve, reject) => {
        poolList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchDetailInvestment(ctx, condition) {
      return new Promise((resolve, reject) => {
        poolDetail(condition.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchWithdrawList(ctx, condition) {
      return new Promise((resolve, reject) => {
        withDrawList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchDetailWithdraw(ctx, condition) {
      return new Promise((resolve, reject) => {
        withdrawDetail(condition.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
