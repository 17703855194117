<template>
  <div>
    <investment-filter
      :type-options="typeOptions"
      :type-filter.sync="typeFilter"
      :status-options="statusOptions"
      :status-filter.sync="statusFilter"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="6" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative cursor-pointer"
        :items="fetchWithdrawList"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        @row-clicked="myHandler"
      >
        <!-- Column: investment -->
        <template #cell(customer)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :variant="'light-primary'" />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.customer.customerName }}
            </span>
            <small class="text-muted">{{ data.item.customer.customerCompany }}</small>
          </b-media>
        </template>
        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <span>{{ numberWithCommas(data.item.amount) }}</span>
        </template>
        <!-- Column: Status -->
        <template #cell(statusMessage)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.statusCode)}`"
            class="text-capitalize"
          >
            {{ data.item.statusMessage }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import InvestmentFilter from './withdrawFilters.vue';
import useWithdrawList from './useWithdrawList';
import investmentStoreModule from '../../investmentStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    InvestmentFilter,
    vSelect,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'app-investment';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, investmentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    const myHandler = (val) => {
      root.$router.push(`withdraw/${val.id}`);
    };
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchWithdrawList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
    } = useWithdrawList();
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchWithdrawList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,

      numberWithCommas,
      myHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
