import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'code', sortable: true, label: 'Mã HĐ' },
    { key: 'customerName', sortable: true, label: 'Khách hàng' },
    { key: 'name', sortable: true, label: 'Gói đầu tư' },
    { key: 'amount', sortable: true, label: 'Số tiền' },
    { key: 'statusMessage', sortable: true, label: 'trạng thái' },
    { key: 'createdAt', sortable: true, label: 'Ngày đăng ký' },
    // { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // const searchQuery = ref('');
  // const sortBy = ref('id');
  // const isSortDirDesc = ref(true);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, statusFilter, typeFilter, startDate, endDate], () => {
    refetchData();
  });

  const fetchWithdrawList = (ctx, callback) => {
    store
      .dispatch('app-investment/fetchWithdrawList', {
        limit: perPage.value,
        page: currentPage.value,
        status: statusFilter.value,
        type: typeFilter.value,
        startDate: startDate.value,
        endDate: endDate.value,
      })
      .then((response) => {
        const { data, meta } = response.data;
        callback(data);
        totalContract.value = meta.total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 5) return 'error';
    if (value === 4) return 'success';
    return 'warning';
  };

  const statusOptions = [
    { label: 'Mới', value: 0 },
    { label: 'Đang xử lý', value: 1 },
    { label: 'Thành công', value: 2 },
    { label: 'Thất bại', value: 3 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchWithdrawList,
    tableColumns,
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    statusFilter,
    typeFilter,
    resolveUserStatusVariant,
    refetchData,
  };
}
