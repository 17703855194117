import jwt from '@/auth/jwt/useJwt';

export function poolList(condition) {
  return jwt.axiosIns.get('/pools/investments', {
    params: {
      ...condition,
    },
  });
}

export function withDrawList(condition) {
  return jwt.axiosIns.get('/pools/withdraws', {
    params: {
      ...condition,
    },
  });
}

export function poolDetail(id) {
  return jwt.axiosIns.get(`pools/investments/${id}`);
}

export function withdrawDetail(id) {
  return jwt.axiosIns.get(`pools/withdraws/${id}`);
}
export function updateStatusInvest(id, status) {
  return jwt.axiosIns.put(`pools/investments/${id}/status`, {
    status,
  });
}
export function confirmInvest(id, data) {
  return jwt.axiosIns.put(`pools/investments/${id}`, data);
}

export function confirmWithdraw(id, transactionCode) {
  return jwt.axiosIns.put(`pool/withdraws/${id}`, {
    code: transactionCode,
  });
}
